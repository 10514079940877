import '@/styles/globals.css'
import 'tailwindcss/tailwind.css'
// Import the styles provided by the react-pdf-viewer packages
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import type { AppProps } from 'next/app'
import { SessionProvider } from "next-auth/react";
import { StyledEngineProvider } from '@mui/material/styles';

// export default function App({ Component, pageProps }: AppProps) {
//   return <Component {...pageProps} />
// }

export default function App({ Component, pageProps }: AppProps) {
  return (
    <StyledEngineProvider injectFirst>
      <SessionProvider session={pageProps.session}>
        <Component {...pageProps} />
      </SessionProvider>
    </StyledEngineProvider>
  )
}
